<template>
  <modal
    name="personalize-packaging-modal"
    :classes="['modal-window']"
    transition="scale"
    scrollable
    adaptive
    :width="900"
    height="auto"
  >
    <div class="modal-container">
      <personalize-packaging @create-treatment="hideModal" />
    </div>
  </modal>
</template>

<script>
import PersonalizePackaging from '@/modules/dashboard/components/dashboard/personalize-packaging/PersonalizePackaging';

export default {
  name: 'PersonalizePackagingModal',
  components: { PersonalizePackaging },
  methods: {
    hideModal() {
      this.$modal.hide('personalize-packaging-modal');
    }
  }
};
</script>

<style scoped>
.modal-container {
  overflow: hidden;
}
</style>
