<template>
  <div>
    <div class="filter__label">
      {{ $t('dashboard.label.filterByStatus') }}
    </div>
    <div class="filter__statuses__container">
      <div
        v-for="status in statusesBinding"
        :key="status.value"
        class="filter__item-container flex--space-between--center pointer"
        @click="pickStatus(status.value)"
      >
        <div class="filter__item__name-container">
          <div class="filter__status__icon">
            <notification-subject v-if="status.isIconPresent" :subject="status.value" />
          </div>
          <div
            class="filter__item__name"
            :class="{ 'filter__item__name--picked': pickedStatus === status.value }"
          >
            {{ status.name }}
          </div>
        </div>
        <div class="filter__item__checkbox">
          <input
            :id="status.value"
            :value="status.value"
            :checked="status.value === pickedStatus"
            type="radio"
          />
          <label :for="status.value"></label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NotificationSubject from '@/modules/dashboard/components/notifications/NotificationSubject';
import FilterItem from '@/modules/dashboard/components/patient-filter/FilterItem';

import { getAllPatientFilterItem } from '@/modules/dashboard/utils';

import { NOTIFICATION_TYPE } from '@/modules/dashboard/api/constants';

const getStatusBindings = t => [
  new FilterItem({
    name: t('dashboard.label.newPhotoAnalysis'),
    value: NOTIFICATION_TYPE.NEW_PHOTO_ANALYSIS,
    isIconPresent: true
  }),
  new FilterItem({
    name: t('dashboard.label.treatmentCompleted'),
    value: NOTIFICATION_TYPE.TREATMENT_COMPLETED,
    isIconPresent: true
  }),
  new FilterItem({
    name: t('dashboard.label.newQuestionnaire'),
    value: NOTIFICATION_TYPE.NEW_QUESTIONNAIRE,
    isIconPresent: true
  }),
  new FilterItem({
    name: t('dashboard.label.ongoingTreatment'),
    value: NOTIFICATION_TYPE.ONGOING_TREATMENT,
    isIconPresent: true
  })
];

const getWebshopStatusBindings = t => [
  new FilterItem({
    name: t('dashboard.patientStatus.offerSent'),
    value: NOTIFICATION_TYPE.OFFER_SENT,
    isIconPresent: true
  }),
  new FilterItem({
    name: t('dashboard.status.underPreparation'),
    value: NOTIFICATION_TYPE.ORDER_UNDER_DELIVERY,
    isIconPresent: true
  })
];

const getInactiveStatusBinding = t =>
  new FilterItem({
    name: t('dashboard.label.allExceptInactive'),
    value: NOTIFICATION_TYPE.INACTIVE,
    isIconPresent: true
  });

export default {
  name: 'FilterByStatuses',
  components: { NotificationSubject },
  props: {
    pickedStatus: {
      type: String,
      required: true
    },
    showWebshopStatuses: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    statusesBinding() {
      const webshopStatusBindings = this.showWebshopStatuses
        ? getWebshopStatusBindings(this.$t)
        : [];

      return [
        getAllPatientFilterItem(this.$t),
        ...getStatusBindings(this.$t),
        ...webshopStatusBindings,
        getInactiveStatusBinding(this.$t)
      ];
    }
  },
  methods: {
    pickStatus(value) {
      this.$emit('status-update', value);
    }
  }
};
</script>

<style lang="scss" scoped>
@import './filters';
</style>
