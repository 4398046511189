import { render, staticRenderFns } from "./MarkAsInactiveModal.vue?vue&type=template&id=1ce4f8f4&scoped=true&"
import script from "./MarkAsInactiveModal.vue?vue&type=script&lang=js&"
export * from "./MarkAsInactiveModal.vue?vue&type=script&lang=js&"
import style0 from "./MarkAsInactiveModal.vue?vue&type=style&index=0&id=1ce4f8f4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ce4f8f4",
  null
  
)

export default component.exports