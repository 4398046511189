<template>
  <div class="patient-list__container">
    <div class="column-titles no-search-field">
      <div
        class="patient-name-column pointer"
        :class="{ active: patientOrder === 'name' }"
        @click="sortByName"
      >
        {{ $t('dashboard.label.patientName') }}&nbsp;
        <span
          class="arrow"
          :class="{ 'arrow-down': !sortingDirections.patientNameDecentOrder }"
        ></span>
      </div>
      <div class="patient-status-column">
        <span class="otherXS">{{ $t('dashboard.label.status') }}</span>
      </div>
      <div
        class="patient-last-activity-column pointer"
        :class="{ active: patientOrder === 'lastActivity' }"
        @click="sortByDate('lastActivity')"
      >
        {{ $t('dashboard.label.lastActivity') }}&nbsp;
        <span
          class="arrow"
          :class="{ 'arrow-down': !sortingDirections.lastActivityDecentOrder }"
        ></span>
      </div>
      <div
        class="patient-registration-date-column pointer onlyLG"
        :class="{ active: patientOrder === 'creationDate' }"
        @click="sortByDate('creationDate')"
      >
        {{ $t('dashboard.label.registrationDate') }}&nbsp;
        <span
          class="arrow"
          :class="{ 'arrow-down': !sortingDirections.registrationDecentOrder }"
        ></span>
      </div>
    </div>
    <div class="patient-list">
      <q-infinite-loader
        v-if="isTopLoaderVisible"
        ref="topPatientLoader"
        direction="top"
        spinner="waveDots"
        @infinite="topInfiniteHandler"
      >
        <span slot="no-results"></span>
        <span slot="no-more"></span>
      </q-infinite-loader>
      <template v-for="(group, index) in sortedGroups">
        <div :id="groupId(group)" :key="index" class="patient-group" :class="order()">
          <div v-if="groupedPatients.hasOwnProperty(group)">
            <div class="category-title-container">
              <div class="category-title">
                {{ groupLabel(group) }}
              </div>
            </div>
          </div>
          <q-patient
            v-for="patient in groupedPatients[group]"
            :id="`id${patient.id}`"
            :key="patient.id"
            :patient="patient"
            :group="group"
            :thumbnail="getPatientThumbnail(patient)"
            :show-registration-date="showRegistrationDate"
          ></q-patient>
        </div>
      </template>
      <alphabet-side-bar
        v-if="alphabetSidebarVisible"
        :alphabet-categories="displayedSidebarLetters"
        :sort-direction="sortDirection"
        @activateSidebar="activateSidebar"
      ></alphabet-side-bar>

      <date-side-bar
        v-if="!alphabetSidebarVisible"
        :active-date-categories="activeDateSidebarCategories"
        :sort-direction="sortDirection"
        @activateSidebar="activateSidebar"
      ></date-side-bar>

      <q-infinite-loader
        v-if="isLoadersVisible"
        ref="patientLoader"
        spinner="waveDots"
        @infinite="infiniteHandler"
      >
        <div slot="no-results" class="patient-loader flex-column--align-center">
          <div
            v-if="isFiltersApplied"
            class="patient-loader__icon custom-icon icon-personalize-packaging-no-matching"
          ></div>
          <p
            class="patient-loader__text main-text-font-size"
            :class="{ 'patient-loader__text--without-filer': !isFiltersApplied }"
          >
            {{ noResultText }}
          </p>
          <p v-if="isFiltersApplied" class="patient-loader__hint">
            {{ $t('dashboard.label.modifySearchCriteria') }}
          </p>
          <p v-else class="empty-list__text">
            {{ $t('dashboard.label.welcomeToSkinxs') }}
          </p>
          <button
            v-if="!isFiltersApplied"
            class="patient-loader__button rounded-button-white"
            @click="showTutorial"
          >
            {{ $t('dashboard.label.howToGetStarted') }}
          </button>
        </div>
        <span slot="no-more"></span>
      </q-infinite-loader>
    </div>
    <mark-as-inactive-modal @refreshPatients="resetPatients"></mark-as-inactive-modal>

    <delete-patient-modal @patient-delete="resetPatients"></delete-patient-modal>
    <tutorial-modal />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import QInfiniteLoader from 'vue-infinite-loading';
import { debounce, groupBy, get, isEmpty } from 'lodash';
import differenceInWeeks from 'date-fns/difference_in_weeks';
import differenceInMonths from 'date-fns/difference_in_months';
import differenceInYears from 'date-fns/difference_in_years';

import QPatient from '@/modules/dashboard/components/patients/Patient';
import MarkAsInactiveModal from '@/modules/dashboard/components/patients/MarkAsInactiveModal';
import DeletePatientModal from '@/modules/dashboard/components/patients/DeletePatientModal';
import AlphabetSideBar from '@/modules/dashboard/components/home/AlphabetSideBar';
import DateSideBar from '@/modules/dashboard/components/home/DateSideBar';
import TutorialModal from '@/modules/dashboard/components/tutorial/TutorialModal';

import { getPatientSortGrouping, handleRoutingInfo } from '@/modules/dashboard/utils';

import { types } from '@/modules/dashboard/store/types';
import rootTypes from '@/store/types';

import { PATIENTS_DOWNLOADING_OFFSET } from '@/constants';
import { SORTING_ORDER, TUTORIAL_ROUTING_INFO } from '@/modules/dashboard/api/constants';

import {
  fetchPatients,
  fetchPatientsThumbnails,
  getSidebarCategories
} from '@/modules/dashboard/api';

const ORDER_BY = {
  NAME: 'name',
  REGISTRATION_DATE: 'creationDate',
  LAST_ACTIVITY: 'lastActivity'
};

const SORT_BY_NAME = 'virtualFullName';

const PATIENT_DATES = {
  REGISTRATION_DATE: 'registrationDate',
  LAST_ACTIVITY_DATE: 'lastActivityDate'
};

const ELAPSED = {
  UNDER_ONE_WEEK: 'underOneWeek',
  ONE_WEEK: 'oneWeek',
  TWO_WEEKS: 'twoWeeks',
  THREE_WEEKS: 'threeWeeks',
  ONE_MONTH: 'oneMonth',
  THREE_MONTHS: 'threeMonths',
  SIX_MONTHS: 'sixMonths',
  NINE_MONTHS: 'nineMonths',
  ONE_YEAR: 'oneYear',
  OVER_TWO_YEARS: 'overTwoYears',
  NO_DATE: 'noDate'
};

const ELAPSED_GRPS = [
  ELAPSED.UNDER_ONE_WEEK,
  ELAPSED.ONE_WEEK,
  ELAPSED.TWO_WEEKS,
  ELAPSED.THREE_WEEKS,
  ELAPSED.ONE_MONTH,
  ELAPSED.THREE_MONTHS,
  ELAPSED.SIX_MONTHS,
  ELAPSED.NINE_MONTHS,
  ELAPSED.ONE_YEAR,
  ELAPSED.OVER_TWO_YEARS,
  ELAPSED.NO_DATE
];

const groupByFirstLetter = parentCtxt => patient => {
  const group = getPatientSortGrouping(patient);

  return parentCtxt.sortLetters.includes(group) ? group : '#';
};

const getDifferenceInYears = date => {
  const diff = differenceInYears(new Date(), date);

  if (diff >= 2) {
    return ELAPSED.OVER_TWO_YEARS;
  }
  if (diff >= 1) {
    return ELAPSED.ONE_YEAR;
  }

  return null;
};

const getDifferenceInMonths = date => {
  const diff = differenceInMonths(new Date(), date);

  if (diff >= 9) {
    return ELAPSED.NINE_MONTHS;
  }
  if (diff >= 6) {
    return ELAPSED.SIX_MONTHS;
  }
  if (diff >= 3) {
    return ELAPSED.THREE_MONTHS;
  }
  if (diff >= 1) {
    return ELAPSED.ONE_MONTH;
  }

  return null;
};

const getDifferenceInWeeks = date => {
  const diff = differenceInWeeks(new Date(), date);

  if (diff >= 3) {
    return ELAPSED.THREE_WEEKS;
  }
  if (diff >= 2) {
    return ELAPSED.TWO_WEEKS;
  }
  if (diff >= 1) {
    return ELAPSED.ONE_WEEK;
  }

  return null;
};

const groupByDate = parentCtxt => patient => {
  const order =
    parentCtxt.patientOrder === ORDER_BY.LAST_ACTIVITY
      ? PATIENT_DATES.LAST_ACTIVITY_DATE
      : PATIENT_DATES.REGISTRATION_DATE;

  const patientDate = patient[order];

  if (order === PATIENT_DATES.LAST_ACTIVITY_DATE && !patientDate) {
    return ELAPSED.NO_DATE;
  }

  const yearsDifference = getDifferenceInYears(patientDate);

  if (yearsDifference) {
    return yearsDifference;
  }

  const monthsDifference = getDifferenceInMonths(patientDate);

  if (monthsDifference) {
    return monthsDifference;
  }

  const daysDifference = getDifferenceInWeeks(patientDate);

  if (daysDifference) {
    return daysDifference;
  }

  return ELAPSED.UNDER_ONE_WEEK;
};

const groupingFuncs = {
  [ORDER_BY.NAME]: groupByFirstLetter,
  [ORDER_BY.REGISTRATION_DATE]: groupByDate,
  [ORDER_BY.LAST_ACTIVITY]: groupByDate
};

const LOADING_DIRECTION = {
  TOP: 'top',
  BOTTOM: 'bottom'
};

export default {
  name: 'PatientList',
  components: {
    DeletePatientModal,
    MarkAsInactiveModal,
    DateSideBar,
    AlphabetSideBar,
    QPatient,
    QInfiniteLoader,
    TutorialModal
  },
  data() {
    return {
      patients: [],
      patientsThumbnails: new Map(),
      currentTopPage: -1,
      currentBottomPage: 0,
      alphabetCategory: 0,
      period: null,
      sidebarActivated: false,
      allPatientsLoaded: false,
      isLoadersVisible: false,
      displayedSidebarLetters: [],
      sortLetters: [],
      periods: [],
      creationPeriods: [],
      lastActivityPeriods: []
    };
  },
  computed: {
    ...mapGetters({
      userId: rootTypes.getters.GET_USER_ID,
      patientNameFilter: types.getters.PARTIAL_NAME,
      patientFilter: types.getters.PATIENT_FILTER,
      savedPatientOrder: types.getters.PATIENTS_ORDER,
      savedPatientDateOrder: types.getters.PATIENTS_DATE_ORDER,
      sortingDirections: types.getters.PATIENTS_SORTING_DIRECTIONS,
      openedPatient: types.getters.OPENED_PATIENT
    }),
    patientOrder: {
      get() {
        return this.savedPatientOrder || ORDER_BY.NAME;
      },
      set(order) {
        this.setPatientOrder(order);
        this.resetPatients();
      }
    },
    patientDateOrder: {
      get() {
        return this.savedPatientDateOrder || ORDER_BY.REGISTRATION_DATE;
      },
      set(order) {
        if (order !== ORDER_BY.NAME) {
          this.setPatientDateOrder(order);
        }
        this.patientOrder = order;
      }
    },
    isTopLoaderVisible() {
      return this.isLoadersVisible && this.sidebarActivated;
    },
    isPatientNameDecentDirection() {
      return this.patientOrder === ORDER_BY.NAME && this.sortingDirections.patientNameDecentOrder;
    },
    isLastActivityReverseDirection() {
      return (
        this.patientOrder === ORDER_BY.LAST_ACTIVITY &&
        !this.sortingDirections.lastActivityDecentOrder
      );
    },
    isRegistrationDateReverseDirection() {
      return (
        this.patientOrder === ORDER_BY.REGISTRATION_DATE &&
        !this.sortingDirections.registrationDecentOrder
      );
    },
    activeDateSidebarCategories() {
      return this.patientDateOrder === ORDER_BY.LAST_ACTIVITY
        ? this.lastActivityPeriods
        : this.creationPeriods;
    },
    isAscPatientColumnsSortingOrder() {
      return (
        this.isPatientNameDecentDirection ||
        this.isLastActivityReverseDirection ||
        this.isRegistrationDateReverseDirection
      );
    },
    sortDirection() {
      return this.isAscPatientColumnsSortingOrder ? SORTING_ORDER.ASC : SORTING_ORDER.DESC;
    },
    sortLettersReversed() {
      return [...this.sortLetters].reverse();
    },
    periodsReversed() {
      return [...this.periods].reverse();
    },
    showRegistrationDate() {
      return this.patientOrder !== ORDER_BY.LAST_ACTIVITY;
    },
    sortedGroups() {
      switch (this.patientOrder) {
        case ORDER_BY.NAME: {
          if (this.sortDirection === SORTING_ORDER.DESC) {
            return this.sortLettersReversed;
          }

          return this.sortLetters;
        }
        case ORDER_BY.LAST_ACTIVITY:
        case ORDER_BY.REGISTRATION_DATE: {
          if (this.sortDirection === SORTING_ORDER.DESC) {
            return this.periods;
          }

          return this.periodsReversed;
        }
        default: {
          return this.sortLetters;
        }
      }
    },
    groupedPatients() {
      if (this.patients.length === 0) {
        return {};
      }

      return this.groupPatients(this.patients, this.patientOrder);
    },
    alphabetSidebarVisible() {
      return this.patientOrder === ORDER_BY.NAME;
    },
    sortName() {
      if (this.patientOrder !== ORDER_BY.NAME) {
        return this.patientOrder === ORDER_BY.LAST_ACTIVITY
          ? PATIENT_DATES.LAST_ACTIVITY_DATE
          : ORDER_BY.REGISTRATION_DATE;
      }

      return SORT_BY_NAME;
    },
    isFiltersApplied() {
      const { statuses, clinics, sources, influencers } = this.patientFilter;
      const hasPatientFilters =
        !isEmpty(statuses) || !isEmpty(clinics) || !isEmpty(sources) || !isEmpty(influencers);

      return this.patientNameFilter !== '' || hasPatientFilters;
    },
    noResultText() {
      return this.$t(
        `dashboard.label.${this.isFiltersApplied ? 'noMatchingPatients' : 'thereNoPatients'}`
      );
    }
  },
  watch: {
    patientNameFilter() {
      this.resetPatients();
    },
    patientFilter() {
      this.resetPatients();
    }
  },
  created() {
    if (!this.patientOrder) {
      this.patientOrder = ORDER_BY.NAME;
    }
  },
  async mounted() {
    if (this.openedPatient) {
      await this.activateSidebar();
    } else {
      this.updateSidebar();
      this.updateFilterCount();
    }

    this.isLoadersVisible = true;
  },
  methods: {
    ...mapActions({
      setPatientOrder: types.actions.SET_PATIENT_ORDER,
      setLoading: rootTypes.actions.SET_LOADING,
      setPatientDateOrder: types.actions.SET_PATIENT_DATE_ORDER,
      setRegistrationDateDirection: types.actions.SET_REGISTRATION_DATE_SORTING_DIRECTION,
      setLastActivityDirection: types.actions.SET_LAST_ACTIVITY_SORTING_DIRECTION,
      setPatientNameDirection: types.actions.SET_PATIENT_NAME_SORTING_DIRECTION,
      resetOpenedPatient: types.actions.RESET_OPENED_PATIENT,
      updatePatientFilterCount: types.actions.UPDATE_PATIENT_FILTER_COUNT
    }),
    async updateFilterCount() {
      const { clinics, statuses, sources, influencers } = this.patientFilter;

      await this.updatePatientFilterCount({
        clinics,
        statuses,
        sources,
        influencers,
        search: this.patientNameFilter
      });
    },
    sortByName() {
      if (this.patientOrder !== ORDER_BY.NAME) {
        this.patientOrder = ORDER_BY.NAME;
      } else {
        this.setPatientNameDirection(!this.sortingDirections.patientNameDecentOrder);
      }

      this.resetPatients();
    },
    sortByDate(dateOrder) {
      if (dateOrder === this.patientOrder) {
        if (dateOrder === ORDER_BY.LAST_ACTIVITY) {
          this.setLastActivityDirection(!this.sortingDirections.lastActivityDecentOrder);
        } else {
          this.setRegistrationDateDirection(!this.sortingDirections.registrationDecentOrder);
        }
      }

      this.patientDateOrder = dateOrder;

      if (this.patientOrder === ORDER_BY.NAME) {
        this.patientOrder = this.patientDateOrder;
      }

      this.resetPatients();
    },
    order() {
      return this.patientOrder === ORDER_BY.NAME ? 'letter' : 'date';
    },
    groupId(group) {
      if (group === '#') {
        return 'letterHash';
      }

      return this.patientOrder === ORDER_BY.NAME
        ? `letter${group}`
        : `date${ELAPSED_GRPS.indexOf(group) + 1}`;
    },
    groupLabel(group) {
      return this.patientOrder === ORDER_BY.NAME
        ? group
        : this.$i18n.t(`dashboard.sidebar.${group}`);
    },
    groupPatients(patients, patientOrder) {
      return groupBy(patients, groupingFuncs[patientOrder](this));
    },
    infiniteHandler: debounce(
      function infiniteHandlerCallback($state) {
        this.activateInfiniteHandler($state);
      },
      500,
      { leading: false, trailing: true }
    ),
    topInfiniteHandler: debounce(
      function infiniteTopHandlerCallback($state) {
        this.activateTopInfiniteHandler($state);
      },
      500,
      { leading: false, trailing: true }
    ),
    async activateInfiniteHandler($state) {
      await this.loadPatients($state, LOADING_DIRECTION.BOTTOM);
    },
    async activateTopInfiniteHandler($state) {
      await this.loadPatients($state, LOADING_DIRECTION.TOP);
    },
    finishDownloading($state) {
      this.allPatientsLoaded = true;
      $state.complete();
    },
    async activateSidebar(scrollId) {
      this.resetPatients();
      this.isLoadersVisible = false;

      await this.updateSidebar();

      this.alphabetCategory = this.sortLetters.includes(scrollId) ? scrollId : 0;
      this.period = this.periods.includes(scrollId) ? scrollId : null;

      const topPatients = await this.loadPatientsFromDirection(LOADING_DIRECTION.TOP);
      const bottomPatients = await this.loadPatientsFromDirection(LOADING_DIRECTION.BOTTOM);

      this.patients = topPatients.concat(this.patients);
      this.patients = this.patients.concat(bottomPatients);

      await this.scrollToGroup({ scrollId });
    },
    async scrollToGroup({ scrollId }) {
      let scrollTo;

      if (this.openedPatient) {
        if (!this.patients.find(({ id }) => id === this.openedPatient.id)) {
          this.updateLoaders();

          return;
        }

        scrollTo = `#id${this.openedPatient.id}`;
      } else {
        const scrollWithHash = scrollId === '#' ? 'Hash' : scrollId;

        scrollTo = `#${this.groupId(scrollWithHash)}`;
      }

      await this.$nextTick();

      this.$scrollTo(scrollTo, 500, {
        easing: 'ease-in',
        offset: -80,
        onDone: this.updateLoaders
      });
    },
    async updateLoaders() {
      this.sidebarActivated = true;
      this.isLoadersVisible = true;

      await this.$nextTick();

      this.resetLoadersState();
    },
    async loadPatients($state, direction) {
      const patients = await this.loadPatientsFromDirection(direction);

      if (patients.length) {
        this.patients =
          LOADING_DIRECTION.TOP === direction
            ? patients.concat(this.patients)
            : this.patients.concat(patients);

        $state.loaded();

        if (patients.length < PATIENTS_DOWNLOADING_OFFSET) {
          this.finishDownloading($state);
        }

        return;
      }

      if (!isEmpty(this.patients)) {
        $state.loaded();
      }

      this.finishDownloading($state);
    },
    async loadPatientsFromDirection(direction) {
      const isTopDirection = direction === LOADING_DIRECTION.TOP;

      if (isTopDirection) {
        const topPatients = await this.downloadPatients({
          page: this.currentTopPage,
          size: PATIENTS_DOWNLOADING_OFFSET
        });

        this.currentTopPage -= 1;

        return topPatients;
      }

      const bottomPatients = await this.downloadPatients({
        page: this.currentBottomPage,
        size: PATIENTS_DOWNLOADING_OFFSET
      });

      this.currentBottomPage += 1;

      return bottomPatients;
    },
    resetLoadersState() {
      const { patientLoader, topPatientLoader } = this.$refs;

      if (patientLoader) {
        patientLoader.stateChanger.reset();
      }

      if (topPatientLoader) {
        topPatientLoader.stateChanger.reset();
      }
    },
    async resetPatients() {
      this.updateSidebar();
      this.updateFilterCount();

      if (this.sidebarActivated) {
        this.resetOpenedPatient();
      }

      this.currentTopPage = -1;
      this.currentBottomPage = 0;
      this.alphabetCategory = 0;
      this.period = null;
      this.sidebarActivated = false;
      this.isLoadersVisible = true;
      this.allPatientsLoaded = false;
      this.patients = [];
      this.$emit('update-patient-count');

      await this.$nextTick();
      this.resetLoadersState();
    },
    async downloadPatients({ page, size }) {
      const {
        clinics: clinicIds,
        statuses: statusNames,
        sources: appearanceSources,
        influencers: influencerIds
      } = this.patientFilter;

      const { data: patients } = await fetchPatients({
        doctorId: this.userId,
        search: this.patientNameFilter,
        firstChar: this.alphabetCategory,
        period: this.period,
        patientId: get(this.openedPatient, 'id', null),
        sortName: this.sortName,
        page,
        size,
        clinicIds,
        statusNames,
        appearanceSources,
        influencerIds,
        sortDirection: this.sortDirection
      });

      await this.downloadPatientsThumbnails(patients);

      return patients;
    },
    async downloadPatientsThumbnails(patient) {
      const { data: thumbnails } = await fetchPatientsThumbnails(patient);

      thumbnails.forEach(({ photoId, convertedToBase64Photo }) => {
        this.patientsThumbnails.set(photoId, convertedToBase64Photo);
      });
    },
    getPatientThumbnail({ photoId }) {
      return this.patientsThumbnails.get(photoId);
    },
    async updateSidebar() {
      const { clinics: clinicIds, statuses: statusNames } = this.patientFilter;

      const {
        data: { alphabetDtos, allSortLetters, generalPeriods, creationPeriods, lastActivityPeriods }
      } = await getSidebarCategories({
        doctorId: this.userId,
        searchQuery: this.patientNameFilter,
        sortName: this.sortName,
        clinicIds,
        statusNames
      });

      this.displayedSidebarLetters = alphabetDtos;
      this.sortLetters = allSortLetters;
      this.periods = generalPeriods;
      this.creationPeriods = creationPeriods;
      this.lastActivityPeriods = lastActivityPeriods;
    },
    showTutorial() {
      handleRoutingInfo(TUTORIAL_ROUTING_INFO, this.$router, this.$modal);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/css/dashboard/patient-table.css';
@import '../../../../assets/scss/helpers/colors';
@import '../../../../assets/scss/common/text';
@import '../../../../assets/scss/common/buttons';

.patient-list__container {
  margin-top: 30px;
}

.column-titles {
  display: flex;

  font-size: 13px;
  line-height: 15px;

  color: $dim-grey-color;
}

.patient-group {
  margin-top: 8px;
}

.patient-name-column {
  padding-left: 60px;
}

.category-title {
  width: 40px;
  display: inline-block;

  text-align: center;

  word-spacing: 100px;

  font-size: 12px;
  color: $dim-grey-color;
  text-transform: uppercase;
  line-height: 12px;

  &-container {
    overflow: hidden;
    width: 100%;
    position: relative;

    font-size: 15px;
    line-height: 18px;
    color: $dim-grey-color;

    &:after {
      position: absolute;
      top: 50%;
      overflow: hidden;
      width: 100%;
      margin-left: 5px;
      height: 1px;
      content: '\A0';
      background-color: $dim-white;
    }
  }
}

.date .category-title {
  font-size: 10px;
  line-height: 12px;
  text-transform: none;
}

.patient-loader {
  &__icon {
    margin: 100px 0 60px;
  }

  &__text {
    &--without-filer {
      margin-top: 100px;
    }
  }

  &__text,
  &__hint {
    color: $grey-color;
  }

  &__hint {
    font-weight: 300;
    font-size: 11px;
    line-height: 16px;
    text-align: center;

    max-width: 200px;
    margin-top: 20px;
  }

  &__button {
    margin-top: 45px;
  }
}

.empty-list {
  &__text {
    max-width: 600px;
    margin: 80px 0 60px;
    font-size: 15px;
    line-height: 17px;
    color: $text-color;
  }
}

.arrow {
  background: url('~@/assets/images/dashboard/icons/arrow-down-icon.svg') no-repeat center center;
  width: 8px;
  height: 8px;
  background-size: contain;
  display: inline-block;
}

.arrow-down {
  transform: rotate(180deg);
}

.active {
  color: $active-title-color;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .category-title-container {
    padding-left: 29px;
  }
}

@media screen and (max-width: 767px) {
  .patient-list {
    padding-bottom: 70px;

    &__container {
      margin: 0 5px;
    }
  }

  .patient-name-column {
    padding: 0 10px 0 50px;
  }

  .patient-name-column,
  .patient-last-activity-column {
    font-size: 10px;
    line-height: 12px;
  }

  .category-title {
    margin-left: 10px;
  }

  .patient-status-column {
    width: 7%;
  }

  .patient-last-activity-column {
    width: 28%;
    padding-left: 14px;
    text-align: center;
  }

  .patient-loader {
    &__icon {
      margin-top: 50px;
    }

    &__text {
      &--without-filer {
        margin-top: 50px;
      }
    }
  }

  .empty-list {
    &__text {
      max-width: 80%;
      margin: 60px 0 0;
      font-size: 13px;
      line-height: 15px;
    }
  }
}
</style>
