<template>
  <div class="functional-buttons flex">
    <div
      class="functional-buttons__button flex--center--center"
      @click="handleFunctionalButton(functionalButtonTypes.CREATE_TREATMENT)"
    >
      <div class="icon-chemicals custom-icon otherXS"></div>
      <span class="functional-buttons__button__text">
        {{ $t('dashboard.action.createTreatment') }}
      </span>
    </div>
    <div
      class="functional-buttons__button flex--center--center m0a"
      @click="handleFunctionalButton(functionalButtonTypes.INVITE_PATIENT)"
    >
      <div class="icon-speaker custom-icon otherXS"></div>
      <span class="functional-buttons__button__text">
        {{ $t('dashboard.label.invitePatients') }}
      </span>
    </div>
    <div
      class="functional-buttons__button flex--center--center"
      @click="handleFunctionalButton(functionalButtonTypes.PERSONALIZE_PACKAGING)"
    >
      <div class="icon-test-meter custom-icon otherXS"></div>
      <span class="functional-buttons__button__text">
        {{ $t('dashboard.button.quickLabelPrint') }}
      </span>
    </div>

    <new-treatment-modal />
    <new-treatment-patient-details-modal />

    <treatment-directory-modals />

    <photo-analysis-add-photo-modal
      is-header-with-back-button
      previous-modal-name="new-treatment-patient-details-modal"
    />

    <clarification-modal />
    <analyzing-photo-modal />
    <photo-validation-modal @try-again="openAddAnalyzingPhotoModal" />
    <analysis-results-overview-modal />
    <photo-analysis-result-update-modal />
    <quit-photo-analysis-warning-modal />

    <invite-patient-modal />
    <review-message-modal />
    <share-with-facebook-modal />
    <send-with-email-modal />
    <invite-template-sent-modal />
    <send-with-text-message-modal />
    <personalize-packaging-modal />
  </div>
</template>

<script>
import InvitePatientModal from '@/modules/dashboard/components/dashboard/invite-patient/InvitePatientModal';
import ReviewMessageModal from '@/modules/dashboard/components/create-treatment-tab/ReviewMessageModal';
import ShareWithFacebookModal from '@/modules/dashboard/components/dashboard/invite-patient/facebook/ShareWithFacebookModal';
import SendWithEmailModal from '@/modules/dashboard/components/dashboard/invite-patient/email/SendWithEmailModal';
import InviteTemplateSentModal from '@/modules/dashboard/components/dashboard/invite-patient/InviteTemplateSentModal';
import SendWithTextMessageModal from '@/modules/dashboard/components/dashboard/invite-patient/sms/SendWithTextMessageModal';
import NewTreatmentModal from '@/modules/dashboard/components/dashboard/create-treatment/new-treatment/NewTreatmentModal';
import NewTreatmentPatientDetailsModal from '@/modules/dashboard/components/dashboard/create-treatment/quick-treatment/NewTreatmentPatientDetailsModal';
import PhotoAnalysisAddPhotoModal from '@/modules/dashboard/components/dashboard/create-treatment/photo-analysis/PhotoAnalysisAddPhotoModal';
import ClarificationModal from '@/modules/dashboard/components/dashboard/create-treatment/photo-analysis/ClarificationModal';
import PersonalizePackagingModal from '@/modules/dashboard/components/dashboard/personalize-packaging/PersonalizePackagingModal';
import AnalyzingPhotoModal from '@/modules/dashboard/components/dashboard/create-treatment/photo-analysis/AnalyzingPhotoModal';
import PhotoValidationModal from '@/modules/dashboard/components/dashboard/common/PhotoValidationModal';
import AnalysisResultsOverviewModal from '@/modules/dashboard/components/dashboard/create-treatment/photo-analysis/AnalysisResultsOverviewModal';
import PhotoAnalysisResultUpdateModal from '@/modules/dashboard/components/dashboard/create-treatment/photo-analysis/PhotoAnalysisResultUpdateModal';
import QuitPhotoAnalysisWarningModal from '@/modules/dashboard/components/dashboard/create-treatment/photo-analysis/QuitPhotoAnalysisWarningModal';
import TreatmentDirectoryModals from '@/modules/dashboard/components/common/TreatmentDirectoryModals';

import { handleDashboardFunctionalButton } from '@/modules/dashboard/utils';

import { DASHBOARD_FUNCTIONAL_BUTTON_TYPES } from '@/modules/dashboard/api/constants';

export default {
  name: 'DashboardFunctionalButtons',
  components: {
    TreatmentDirectoryModals,
    QuitPhotoAnalysisWarningModal,
    AnalysisResultsOverviewModal,
    AnalyzingPhotoModal,
    PhotoAnalysisResultUpdateModal,
    ClarificationModal,
    PhotoAnalysisAddPhotoModal,
    NewTreatmentPatientDetailsModal,
    NewTreatmentModal,
    InviteTemplateSentModal,
    SendWithTextMessageModal,
    SendWithEmailModal,
    ShareWithFacebookModal,
    InvitePatientModal,
    PersonalizePackagingModal,
    PhotoValidationModal,
    ReviewMessageModal
  },
  data() {
    return {
      functionalButtonTypes: DASHBOARD_FUNCTIONAL_BUTTON_TYPES
    };
  },
  methods: {
    handleFunctionalButton(type) {
      handleDashboardFunctionalButton(this.$router, this.$modal, type);
    },
    openAddAnalyzingPhotoModal() {
      this.$modal.show('photo-analysis-add-photo-modal');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/css/common/icons.css';
@import '../../../../assets/scss/helpers/colors';

.functional-buttons {
  font-size: 15px;
  line-height: 18px;
  font-weight: bold;
  width: 100%;

  margin: 30px 0;

  color: $active;

  &__button {
    height: 60px;
    width: 30.6%;
    cursor: pointer;

    box-shadow: 0 6px 7px rgba(148, 166, 176, 0.15);
    background-color: white;
    border-radius: 8px;

    &__text {
      margin-left: 10px;
    }
  }
}

@media (min-width: 768px) {
  .functional-buttons {
    &__button:hover {
      background-color: $hovered-button-color;
    }
  }
}

@media (max-width: 991px) {
  .functional-buttons {
    font-size: 13px;
    line-height: 16px;

    &__button {
      width: 32.2%;
    }
  }
}

@media (max-width: 767px) {
  .functional-buttons {
    position: fixed;
    bottom: 0;
    z-index: 1;

    font-size: 12px;
    line-height: 14px;

    padding: 10px 5px;
    margin: 0;

    background: linear-gradient(0deg, $white 48.72%, rgba(255, 255, 255, 0) 93.55%);

    &__button {
      height: 50px;
      padding: 10px 20px;

      &__text {
        text-align: center;
        margin-left: 0;
      }
    }
  }
}
</style>
