const stickySidebarMixin = {
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    document.body.classList.add('overflow-y--scroll');
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
    document.body.classList.remove('overflow-y--scroll');
  },
  methods: {
    handleScroll() {
      const { sideBar } = this.$refs;
      const scrollTop = window.pageYOffset;
      const offsetTop = 200;

      if (scrollTop > offsetTop) {
        sideBar.classList.add('sticky-sidebar');
      } else {
        sideBar.classList.remove('sticky-sidebar');
      }
    }
  }
};

export default stickySidebarMixin;
