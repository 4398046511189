<template>
  <div class="invite-patient">
    <header class="invite-patient__header flex--space-between--center">
      <span class="no-search-field">
        {{ $t('dashboard.label.sendInvites') }}
      </span>
      <div class="icon-close custom-icon" @click="closeInvitePatient"></div>
    </header>
    <div class="divider"></div>
    <main class="invite-patient__content flex-column--align-center">
      <div class="icon-marketing-speaker custom-icon otherXS"></div>
      <span class="invite-patient__content__invite-text">
        {{ $t('dashboard.label.invitePatientsVia') }}
      </span>
      <invite-patient-buttons class="invite-patient__content__buttons" />
      <div class="divider full-width"></div>
      <a
        class="invite-patient__content__questionnaire-link no-search-field"
        :href="doctorLink"
        target="_blank"
        @click="onDoctorQuestionnaireLinkClick"
      >
        {{ doctorLink }}
      </a>
      <copy-to-clipboard :text-to-copy="doctorLink">
        <div slot="copy-button" class="flex--align-center rounded-button-white pointer">
          <div class="icon-copy-grey custom-icon"></div>
          <span>{{ $t('dashboard.action.copyLink') }}</span>
        </div>
      </copy-to-clipboard>
    </main>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import InvitePatientButtons from '@/modules/dashboard/components/dashboard/invite-patient/InvitePatientButtons';
import CopyToClipboard from '@/modules/dashboard/components/common/CopyToClipboard';

import { isMobileDevice } from '@/modules/dashboard/utils';

import rootTypes from '@/store/types';
import { types as dashTypes } from '@/modules/dashboard/store/types';

export default {
  name: 'InvitePatient',
  components: { CopyToClipboard, InvitePatientButtons },
  computed: {
    ...mapGetters({
      doctorLink: rootTypes.getters.DOCTOR_QUESTIONNAIRE_LINK
    })
  },
  methods: {
    ...mapMutations({
      setIsDashboardLocked: dashTypes.mutations.SET_IS_DASHBOARD_LOCKED
    }),
    onDoctorQuestionnaireLinkClick() {
      this.setIsDashboardLocked(true);
      this.$modal.show('unlock-dashboard-modal');
    },
    closeInvitePatient() {
      if (isMobileDevice()) {
        this.$router.push({ name: 'Dashboard' });

        return;
      }

      this.$modal.hide('invite-patient-modal');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/css/common/icons.css';
@import '../../../../../assets/scss/helpers/colors';
@import '../../../../../assets/scss/common/buttons';

.invite-patient {
  font-size: 15px;
  line-height: 17px;
  color: $text-color;

  &__header {
    height: 50px;
    padding: 0 20px;
  }

  &__content {
    padding: 35px 90px 30px 90px;

    &__invite-text {
      margin: 40px 0;
      color: $grey-color;
    }

    &__buttons {
      margin-bottom: 30px;
    }

    &__questionnaire-link {
      margin: 30px 0;
      color: $links-color;
    }
  }
}

.icon-copy-grey {
  margin-right: 10px;
}

.icon-close {
  padding: 10px;
  background-size: 12px;
}

@media (max-width: 767px) {
  .invite-patient {
    background: $white;
    font-size: 13px;
    line-height: 16px;
    min-height: 100vh;

    &__header {
      font-weight: bold;
    }

    &__content {
      padding: 60px 24px;

      &__invite-text {
        font-size: 12px;
        line-height: 18px;
        margin-top: 0;
        margin-bottom: 50px;
      }

      &__buttons {
        margin-bottom: 65px;
      }

      &__questionnaire-link {
        margin: 30px 0 24px 0;
      }
    }
  }
}
</style>
