<template>
  <div ref="sideBar" class="sideBar date-side-bar filterDate otherXS">
    <a
      v-for="(category, index) in sidebarCategories"
      :key="index + category.label"
      class="category"
      :class="{ inActive: !category.active }"
      @click="activateSidebar(category)"
    >
      {{ category.label }}
    </a>
  </div>
</template>

<script>
import stickySidebarMixin from '@/modules/dashboard/components/mixins/stickySidebarMixin';

import { SORTING_ORDER } from '@/modules/dashboard/api/constants';

export default {
  name: 'DateSideBar',
  mixins: [stickySidebarMixin],
  props: {
    activeDateCategories: {
      type: Array,
      required: true
    },
    sortDirection: {
      type: String,
      required: false,
      default: SORTING_ORDER.DESC
    }
  },
  computed: {
    labeledDateCategories() {
      return this.activeDateCategories.reduce((accumulator, { period, active }) => {
        accumulator.push({
          label: this.$t(`dashboard.group.${period}`),
          period,
          active
        });

        return accumulator;
      }, []);
    },
    labeledDateCategoriesReversed() {
      return [...this.labeledDateCategories].reverse();
    },
    sidebarCategories() {
      if (this.sortDirection === SORTING_ORDER.ASC) {
        return this.labeledDateCategoriesReversed;
      }

      return this.labeledDateCategories;
    }
  },
  methods: {
    activateSidebar(category) {
      if (category.active) {
        this.$emit('activateSidebar', category.period);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';

.date-side-bar {
  margin-left: 8px;
}

.category {
  text-align: right;
  font-size: 11px;
  line-height: 150%;
}
</style>
