<template>
  <modal
    name="send-with-text-message-modal"
    :classes="['modal-window']"
    transition="scale"
    scrollable
    adaptive
    width="800px"
    height="auto"
  >
    <div class="modal-container">
      <send-with-text-message />
    </div>
  </modal>
</template>

<script>
import SendWithTextMessage from '@/modules/dashboard/components/dashboard/invite-patient/sms/SendWithTextMessage';

export default {
  name: 'SendWithTextMessageModal',
  components: { SendWithTextMessage }
};
</script>
