<template>
  <modal
    name="patient-filter-modal"
    :classes="['modal-window']"
    transition="scale"
    :scrollable="true"
    :adaptive="true"
    :reset="true"
    width="300"
    height="auto"
  >
    <div class="modal-container">
      <div class="modal-window-content">
        <PatientFilter />
      </div>
    </div>
  </modal>
</template>

<script>
import PatientFilter from '@/modules/dashboard/components/patient-filter/PatientFilter';

export default {
  name: 'PatientFilterModal',
  components: { PatientFilter }
};
</script>

<style scoped>
.modal-container {
  margin: 0;
}
</style>
