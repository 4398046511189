<template>
  <modal
    name="invite-patient-modal"
    :classes="['modal-window']"
    transition="scale"
    scrollable
    adaptive
    width="800px"
    height="auto"
  >
    <div class="modal-container">
      <invite-patient />
    </div>
  </modal>
</template>

<script>
import InvitePatient from '@/modules/dashboard/components/dashboard/invite-patient/InvitePatient';

export default {
  name: 'InvitePatientModal',
  components: { InvitePatient }
};
</script>
