<template>
  <div>
    <div class="separator mt15 mr20 ml20"></div>
    <div class="mt20">
      <div class="filter__label flex--space-between--center">
        <span>{{ $t('dashboard.patientFilter.filterBySource') }}</span>
        <div
          v-tooltip="{
            hide: 100,
            content: filterHint,
            placement: 'right-start',
            classes: 'filter-tooltip',
            trigger: 'click hover'
          }"
          class="custom-icon icon-help"
        ></div>
      </div>

      <div class="filter__source__container">
        <div
          v-for="source in sourceBinding"
          :key="source.name"
          class="filter__item-container flex--space-between--center pointer"
          @click="pickSource(source)"
        >
          <div class="filter__item__name-container">
            <div :class="source.icon"></div>
            <div
              class="filter__item__name"
              :class="{ 'filter__item__name--picked': getIsSourceIncluded(source) }"
            >
              {{ source.name }}
            </div>
          </div>
          <div class="filter__item__checkbox">
            <input
              :id="source.name"
              :value="source.value"
              :checked="getIsSourceIncluded(source)"
              type="checkbox"
            />
            <label :for="source.name"></label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import FilterItem from '@/modules/dashboard/components/patient-filter/FilterItem';

import { getAllPatientFilterItem } from '@/modules/dashboard/utils';

import { PATIENT_SOURCE, PATIENT_SOURCE_VALUE } from '@/modules/dashboard/api/constants';
import rootTypes from '@/store/types';

const getSourceBindings = $t => [
  new FilterItem({
    name: $t(PATIENT_SOURCE_VALUE.CREATED_BY_DOCTOR),
    value: `${PATIENT_SOURCE.CREATED_BY_DOCTOR}`,
    isIconPresent: true,
    icon: 'icon-created-by-user'
  }),
  new FilterItem({
    name: $t(PATIENT_SOURCE_VALUE.INVITED_BY_DOCTOR),
    value: `${PATIENT_SOURCE.INVITED_BY_DOCTOR}`,
    isIconPresent: true,
    icon: 'icon-invited-by-user'
  })
];

export default {
  name: 'FilterBySources',
  props: {
    pickedSource: {
      type: [String, Number],
      required: true
    }
  },
  computed: {
    ...mapGetters({
      doctorInfluencers: rootTypes.getters.GET_USER_INFLUENCERS
    }),
    sourceBinding() {
      const influencers = this.doctorInfluencers.map(
        ({ name, influencerId }) =>
          new FilterItem({
            name,
            value: influencerId,
            isIconPresent: true,
            icon: 'icon-influencer'
          })
      );

      const defaultSources = getSourceBindings(this.$t);

      return [getAllPatientFilterItem(this.$t), ...defaultSources, ...influencers];
    },
    influencerHintMessage() {
      return this.doctorInfluencers.length
        ? `<div class="flex mt10">
            <span class="custom-icon icon-invited-by-influencer mr10"></span>
            ${this.$t('dashboard.patientFilter.invitedByInfluencerHintMessage')}
        </div>`
        : '';
    },
    filterHint() {
      return `<div class="hint-content">
                <div class="flex mb10">
                  <span class="custom-icon icon-created-by-user mr10"></span>
                  ${this.$t('dashboard.patientFilter.createdbyUserHintMessage')}
               </div>
                <div class="flex">
                 <span class="custom-icon icon-invited-by-user mr10"></span>
                  ${this.$t('dashboard.patientFilter.invitedByUserHintMessage')}
                 </div>
                 ${this.influencerHintMessage}
              </div>`;
    }
  },
  methods: {
    getIsSourceIncluded({ value }) {
      return this.pickedSource === value;
    },
    pickSource({ value }) {
      this.$emit('source-update', value);
    }
  }
};
</script>

<style lang="scss" scoped>
@import './filters';
</style>
