<template>
  <modal
    name="tutorial-modal"
    :classes="['modal-window']"
    transition="scale"
    adaptive
    scrollable
    :width="800"
    :height="700"
  >
    <div class="modal-container">
      <modal-header current-modal-name="tutorial-modal">
        <div slot="header-title">
          <span class="modal__title">
            {{ $t('dashboard.tutorial.tutorialHowToCreate') }}
          </span>
        </div>
      </modal-header>
      <tutorial />
    </div>
  </modal>
</template>

<script>
import ModalHeader from '@/modules/dashboard/components/dashboard/common/ModalHeader';
import Tutorial from '@/modules/dashboard/components/tutorial/Tutorial';

export default {
  name: 'TutorialModal',
  components: { ModalHeader, Tutorial }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';

.modal {
  &__title {
    font-size: 15px;
    color: $text-color;
  }
}
</style>
