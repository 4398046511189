<template>
  <div>
    <template-with-header display-search-icon>
      <dashboard-functional-buttons />
      <div class="p0">
        <div class="dashboard__action-container">
          <div
            class="dashboard__action-container__left-block flex--align-center"
            :class="{ 'dashboard__action-container__left-block__filter-applied': isFilterApplied }"
          >
            <p class="m0 dashboard__patient-label no-search-field">
              {{ $t('dashboard.label.patients') }}
            </p>
            <div class="dashboard__filters-container flex--space-between--center">
              <div v-show="!isFilterApplied" class="pointer" @click="openFilterModal">
                <img
                  class="dashboard__filter-icon"
                  alt="Filter"
                  title="Filter"
                  src="@/assets/images/dashboard/icons/filter.svg"
                />
              </div>
              <div
                v-show="isFilterApplied"
                class="dashboard__filter-container pointer flex--space-between--center"
                @click="openFilterModal"
              >
                <img
                  class="mr10 dashboard__filter-img"
                  alt="Filter Applied"
                  title="Filter Applied"
                  src="@/assets/images/dashboard/icons/filter-applied.svg"
                />
                <div class="dashboard__filter__label">{{ $t('dashboard.label.filtered') }}</div>
                <a class="dashboard__filter__reset-button ml10" @click.stop.prevent="resetFilter">
                  {{ $t('dashboard.button.reset') }}
                </a>
              </div>
            </div>
            <SearchField
              class="dashboard__search-field"
              @cross-icon-click="clearSearchField"
            ></SearchField>
            <p class="m0 dashboard__patient-count flex--align-center no-search-field">
              <span v-if="isPatientsFiltered" class="dashboard__filter__label">
                {{ patientFilterCount }} {{ $t('dashboard.label.shown') }}
              </span>
              <span v-if="isPatientsFiltered" class="dashboard__patient-count-separator">/</span>
              <span class="dashboard__patient-count-label">
                {{ $t('dashboard.label.total', [totalPatientCount]) }}
              </span>
            </p>
          </div>
        </div>
      </div>
      <div class="dashboard__patient-list">
        <q-patient-list @update-patient-count="updatePatientCount"></q-patient-list>
      </div>
    </template-with-header>

    <patient-filter-modal></patient-filter-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { isEmpty } from 'lodash';

import TemplateWithHeader from '@/modules/dashboard/components/templates/TemplateWithHeader';
import PatientFilterModal from '@/modules/dashboard/components/patient-filter/PatientFilterModal';
import SearchField from '@/modules/dashboard/components/common/SearchField';
import QPatientList from '@/modules/dashboard/components/patients/PatientList';
import DashboardFunctionalButtons from '@/modules/dashboard/components/dashboard/DashboardFunctionalButtons';

import { countPatients } from '@/modules/dashboard/api';

import rootTypes from '@/store/types';
import { types as dashTypes } from '@/modules/dashboard/store/types';

import PLUS_ICON_IMAGE from '@/assets/images/dashboard/icons/plus-active-icon.svg';

export default {
  name: 'Dashboard',
  components: {
    DashboardFunctionalButtons,
    TemplateWithHeader,
    PatientFilterModal,
    SearchField,
    QPatientList
  },
  data() {
    return {
      totalPatientCount: 0
    };
  },
  computed: {
    ...mapGetters({
      userId: rootTypes.getters.GET_USER_ID,
      patientFilterCount: dashTypes.getters.PATIENT_FILTER_COUNT,
      patientFilter: dashTypes.getters.PATIENT_FILTER
    }),
    isPatientsFiltered() {
      return this.patientFilterCount !== null && this.patientFilterCount < this.totalPatientCount;
    },
    isFilterApplied() {
      const { clinics, statuses, sources, influencers } = this.patientFilter;

      return !isEmpty(clinics) || !isEmpty(statuses) || !isEmpty(sources) || !isEmpty(influencers);
    }
  },
  async created() {
    await this.updatePatientCount();
  },
  mounted() {
    this.preloadImage(PLUS_ICON_IMAGE);
  },
  methods: {
    ...mapActions({
      updateFilter: dashTypes.actions.UPDATE_FILTER,
      resetPatientFilter: dashTypes.actions.RESET_PATIENT_FILTER
    }),
    openFilterModal() {
      this.$modal.show('patient-filter-modal');
    },
    async resetFilter() {
      await this.resetPatientFilter();
    },
    async updatePatientCount() {
      const { data } = await countPatients({
        doctorId: this.userId
      });
      this.totalPatientCount = data;
    },
    clearSearchField() {
      this.updateFilter('');
    },
    preloadImage(src) {
      const image = new Image();

      image.src = src;
    }
  },
  metaInfo: {
    title: 'Dashboard',
    titleTemplate: 'skinXs - %s'
  }
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/helpers/colors';

.dashboard {
  &__action-container {
    width: 100%;

    display: flex;

    &__left-block {
      width: 100%;
    }
  }

  &__patient-label,
  &__filters-container,
  &__search-field,
  &__patient-count {
    margin-right: 20px;
  }

  &__patient-label {
    font-weight: 600;
    font-size: 18px;
  }

  &__patient-count {
    flex-direction: row-reverse;
  }

  &__patient-count-label {
    font-size: 16px;
    color: $active;
  }

  &__patient-count-separator,
  &__patient-count-separator + &__patient-count-label {
    color: $grey-color;
  }

  &__filter {
    &-container {
      height: 36px;

      padding: 0 15px;

      font-size: 15px;

      background-color: $dim-white;
      border-radius: 50px;
    }

    &__label {
      color: $active;
    }

    &__reset-button {
      color: $grey-color;
    }
  }

  &__patient-list {
    margin: 0 20px;
  }
}

@media screen and (min-width: 1200px) {
  .containerAlt {
    max-width: 950px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .containerAlt {
    max-width: 920px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .containerAlt {
    max-width: 683px;
  }
}

@media screen and (max-width: 767px) {
  .containerAlt {
    padding: 0;
    overflow-x: hidden;
  }
}

@media (min-width: 768px) {
  .dashboard {
    &__patient-count {
      margin-top: 3px;
    }
  }
}

@media (max-width: 767px) {
  .dashboard {
    &__action-container {
      margin: 15px 0;

      &__left-block {
        width: 100%;

        min-height: 36px;
        margin: 0 15px;
      }
    }

    &__filters-container {
      order: 1;
      margin-right: 0;
    }

    &__patient-count {
      margin: 0 10px 0 auto;
    }

    &__patient-count-label,
    &__patient-count-separator {
      font-size: 13px;
      line-height: 15px;
    }

    &__filter {
      &__reset-button {
        display: none;
      }

      &__label {
        font-size: 13px;
        line-height: 15px;
      }

      &-icon {
        margin: 0;
      }
    }

    &__filter-container {
      padding: 0 12px;

      .dashboard__filter__label {
        display: none;
      }

      .dashboard__filter-img {
        margin-right: 0;
      }
    }

    &__search-field {
      &.search-field-container {
        display: none;
      }
    }

    &__patient-list {
      margin: 0;
      overflow: hidden;
    }
  }

  /deep/ .content-container.template-content-container {
    background: $background;
  }
}
</style>
