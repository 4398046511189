<template>
  <modal
    name="share-with-facebook-modal"
    :classes="['modal-window']"
    transition="scale"
    scrollable
    adaptive
    width="800px"
    height="auto"
  >
    <div class="modal-container">
      <share-with-facebook />
    </div>
  </modal>
</template>

<script>
import ShareWithFacebook from '@/modules/dashboard/components/dashboard/invite-patient/facebook/ShareWithFacebook';

export default {
  name: 'ShareWithFacebookModal',
  components: { ShareWithFacebook }
};
</script>
