<template>
  <modal
    name="send-with-email-modal"
    :classes="['modal-window']"
    transition="scale"
    scrollable
    adaptive
    width="800px"
    height="auto"
  >
    <div class="modal-container">
      <send-with-email />
    </div>
  </modal>
</template>

<script>
import SendWithEmail from '@/modules/dashboard/components/dashboard/invite-patient/email/SendWithEmail';

export default {
  name: 'SendWithEmailModal',
  components: { SendWithEmail }
};
</script>
