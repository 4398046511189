<template>
  <div>
    <div class="separator mt15 mr20 ml20"></div>
    <div class="mt20">
      <div class="filter__label">
        {{ $t('dashboard.label.filterClinicLocation') }}
      </div>
      <div class="filter__clinic__container">
        <div
          v-for="clinic in clinicBinding"
          :key="clinic.name"
          class="filter__item-container flex--space-between--center pointer"
          @click="checkClinic(clinic.value)"
        >
          <div class="filter__item__name-container">
            <div
              class="filter__clinic__color"
              :style="{ backgroundColor: clinic.isIconPresent ? clinic.color : '#ffffff' }"
            ></div>
            <div
              class="filter__item__name"
              :class="{ 'filter__item__name--picked': getIsClinicIncludes(clinic.value) }"
            >
              {{ clinic.name }}
            </div>
          </div>
          <div class="filter__item__checkbox">
            <input
              :id="clinic.name"
              :value="clinic.value"
              :checked="getIsClinicIncludes(clinic.value)"
              type="checkbox"
              @click="checkClinic(clinic.value)"
            />
            <label :for="clinic.name"></label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import FilterItem from '@/modules/dashboard/components/patient-filter/FilterItem';

import { getAllPatientFilterItem } from '@/modules/dashboard/utils';

import rootTypes from '@/store/types';
import { ALL_VALUE } from '@/modules/dashboard/api/constants';

export default {
  name: 'FilterByClinics',
  props: {
    pickedClinics: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      doctorClinics: rootTypes.getters.GET_USER_CLINICS
    }),
    clinicBinding() {
      const clinics = this.doctorClinics.map(
        ({ clinicName, id, clinicColor }) =>
          new FilterItem({ name: clinicName, value: id, isIconPresent: true, color: clinicColor })
      );

      return [getAllPatientFilterItem(this.$t), ...clinics];
    }
  },
  methods: {
    getIsClinicIncludes(index) {
      return this.pickedClinics.includes(index);
    },
    checkClinic(value) {
      const isChecked = this.pickedClinics.some(id => id === value);
      const isAllValueChecked = value === ALL_VALUE;

      let clinics = [];

      switch (true) {
        case isAllValueChecked: {
          clinics = [value];

          break;
        }

        case !!isChecked: {
          clinics = this.pickedClinics.filter(id => id !== value);

          clinics = clinics.length > 0 ? clinics : [ALL_VALUE];

          break;
        }

        case !isAllValueChecked: {
          clinics = this.pickedClinics.filter(id => id !== ALL_VALUE);
          clinics.push(value);

          break;
        }

        default: {
          clinics.push(value);

          break;
        }
      }

      this.$emit('clinics-update', clinics);
    }
  }
};
</script>

<style lang="scss" scoped>
@import './filters';
</style>
