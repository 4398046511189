<template>
  <div class="filter">
    <div class="filter__header flex--space-between--center">
      <span class="filter__header__title">
        {{ $t('dashboard.label.filter') }}
      </span>
      <div class="icon-close custom-icon" @click="closeFilterModal"></div>
    </div>
    <div class="separator"></div>
    <div ref="scrollable-dropdown" class="filter__container">
      <FilterByStatuses
        :picked-status="pickedStatus"
        :show-webshop-statuses="hasWebshopAccess"
        @status-update="pickStatus"
      />
      <FilterByClinics
        v-if="canShowClinics"
        :picked-clinics="pickedClinics"
        @clinics-update="checkClinic"
      />
      <FilterBySources :picked-source="pickedSource" @source-update="pickSource" />
    </div>
    <div class="filter__footer flex--space-between--center">
      <a class="filter__close-button mr30" @click="resetFilter">
        {{ $t('dashboard.button.reset') }}
      </a>
      <button class="filter__apply-button rounded-button-white" @click="applyFilter">
        {{ $t('dashboard.button.apply') }}
      </button>
    </div>
  </div>
</template>

<script>
import PerfectScrollbar from 'perfect-scrollbar';
import { mapGetters, mapActions } from 'vuex';
import { isEqual } from 'lodash';

import FilterByStatuses from '@/modules/dashboard/components/patient-filter/FilterByStatuses';
import FilterByClinics from '@/modules/dashboard/components/patient-filter/FilterByClinics';
import FilterBySources from '@/modules/dashboard/components/patient-filter/FilterBySources';

import rootTypes from '@/store/types';
import { types as dashTypes } from '@/modules/dashboard/store/types';

import {
  NOTIFICATION_TYPE,
  STATUSES_EXCEPT_INACTIVE,
  PATIENT_SOURCE,
  ALL_VALUE
} from '@/modules/dashboard/api/constants';

export default {
  name: 'PatientFilter',
  components: { FilterByStatuses, FilterByClinics, FilterBySources },
  data() {
    return {
      scrollbar: null,
      pickedStatus: ALL_VALUE,
      pickedClinics: [ALL_VALUE],
      pickedSource: ALL_VALUE
    };
  },
  computed: {
    ...mapGetters({
      patientNameFilter: dashTypes.getters.PARTIAL_NAME,
      doctorClinics: rootTypes.getters.GET_USER_CLINICS,
      patientFilter: dashTypes.getters.PATIENT_FILTER,
      hasWebshopAccess: rootTypes.getters.HAS_WEBSHOP_ACCESS
    }),
    canShowClinics() {
      return this.doctorClinics.length > 1;
    }
  },
  created() {
    const { statuses, clinics, sources, influencers } = this.patientFilter;

    switch (true) {
      case isEqual(statuses, STATUSES_EXCEPT_INACTIVE): {
        this.pickedStatus = NOTIFICATION_TYPE.INACTIVE;

        break;
      }

      case statuses.length > 0: {
        [this.pickedStatus] = statuses;

        break;
      }

      default: {
        this.pickedStatus = ALL_VALUE;
      }
    }

    const [source] = sources;

    switch (true) {
      case source === PATIENT_SOURCE.INVITED_BY_INFLUENCER: {
        [this.pickedSource] = influencers;

        break;
      }

      case sources.length > 0: {
        this.pickedSource = source;

        break;
      }

      default: {
        this.pickedSource = ALL_VALUE;
      }
    }

    this.pickedClinics = clinics.length > 0 ? clinics : [ALL_VALUE];
  },
  mounted() {
    this.scrollbar = new PerfectScrollbar(this.$refs['scrollable-dropdown'], {
      suppressScrollX: true,
      wheelPropagation: false
    });
  },
  methods: {
    ...mapActions({
      setPatientFilter: dashTypes.actions.SET_PATIENT_FILTER,
      resetPatientFilter: dashTypes.actions.RESET_PATIENT_FILTER,
      updatePatientFilterCount: dashTypes.actions.UPDATE_PATIENT_FILTER_COUNT,
      updateSearchFilter: dashTypes.actions.UPDATE_FILTER
    }),
    closeFilterModal() {
      this.$modal.hide('patient-filter-modal');
    },
    pickStatus(value) {
      this.pickedStatus = value;
    },
    checkClinic(value) {
      this.pickedClinics = value;
    },
    pickSource(value) {
      this.pickedSource = value;
    },
    async resetFilter() {
      await this.resetPatientFilter();

      this.closeFilterModal();
    },
    async applyFilter() {
      let statuses;
      let sources;
      let influencers = [];

      switch (this.pickedStatus) {
        case ALL_VALUE: {
          statuses = [];

          break;
        }

        case NOTIFICATION_TYPE.ONGOING_TREATMENT: {
          statuses = [NOTIFICATION_TYPE.ONGOING_TREATMENT, NOTIFICATION_TYPE.TREATMENT_STARTS_IN];

          break;
        }

        case NOTIFICATION_TYPE.INACTIVE: {
          statuses = STATUSES_EXCEPT_INACTIVE;

          break;
        }

        case NOTIFICATION_TYPE.ORDER_UNDER_DELIVERY: {
          statuses = [NOTIFICATION_TYPE.ORDER_UNDER_DELIVERY];

          break;
        }

        default: {
          statuses = [this.pickedStatus];
        }
      }

      switch (this.pickedSource) {
        case ALL_VALUE: {
          sources = [];

          break;
        }

        case PATIENT_SOURCE.CREATED_BY_DOCTOR:
        case PATIENT_SOURCE.INVITED_BY_DOCTOR: {
          sources = [this.pickedSource];

          break;
        }

        default: {
          sources = [PATIENT_SOURCE.INVITED_BY_INFLUENCER];
          influencers = [this.pickedSource];
        }
      }

      const clinics = this.pickedClinics.includes(ALL_VALUE) ? [] : this.pickedClinics;

      await this.updatePatientFilterCount({ statuses, clinics, sources, influencers });

      await this.setPatientFilter({
        statuses,
        clinics,
        sources,
        influencers
      });

      this.closeFilterModal();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~perfect-scrollbar/css/perfect-scrollbar.css';
@import '../../../../assets/css/common/perfect-scrollbar-styles.css';
@import '../../../../assets/scss/common/buttons';
@import '../../../../assets/scss/helpers/colors';

.filter {
  width: 100%;

  display: flex;
  flex-direction: column;

  position: relative;

  &__header {
    height: 50px;

    padding: 20px;

    &__title {
      font-size: 15px;
      color: $text-color;
    }
  }

  &__container {
    height: auto;
    max-height: 400px;
    padding: 20px 0;
    position: relative;
  }

  .icon-close {
    padding: 10px;
    background-size: 12px;
  }

  &__footer {
    width: 100%;
    height: 50px;

    padding: 20px;

    background-color: $background;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    .rounded-button-white {
      padding: 7px 17px;
    }
  }

  &__close-button {
    color: $grey-color;
    transition: all 0.1s;

    &:hover {
      color: $text-color;
      text-decoration: none;
      transition: all 0.1s;
    }
  }
}

.separator {
  border-top: 1px solid #eaeef0;
}
</style>
