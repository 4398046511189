import { render, staticRenderFns } from "./DashboardFunctionalButtons.vue?vue&type=template&id=f5738d1c&scoped=true&"
import script from "./DashboardFunctionalButtons.vue?vue&type=script&lang=js&"
export * from "./DashboardFunctionalButtons.vue?vue&type=script&lang=js&"
import style0 from "./DashboardFunctionalButtons.vue?vue&type=style&index=0&id=f5738d1c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f5738d1c",
  null
  
)

export default component.exports