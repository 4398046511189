<template>
  <modal
    name="new-treatment-patient-details-modal"
    :classes="['modal-window']"
    transition="scale"
    scrollable
    adaptive
    :click-to-close="false"
    width="800px"
    height="auto"
    @before-open="beforeOpen"
  >
    <div class="modal-container">
      <new-treatment-patient-details :new-treatment-flow-type="newTreatmentFlowType" />
    </div>
  </modal>
</template>

<script>
import NewTreatmentPatientDetails from '@/modules/dashboard/components/dashboard/create-treatment/quick-treatment/NewTreatmentPatientDetails';

import { NEW_TREATMENT_FLOW_TYPES } from '@/modules/dashboard/api/constants';

export default {
  name: 'NewTreatmentPatientDetailsModal',
  components: { NewTreatmentPatientDetails },
  data() {
    return {
      newTreatmentFlowType: NEW_TREATMENT_FLOW_TYPES.TREATMENT_FROM_SCRATCH
    };
  },
  methods: {
    beforeOpen({ params: { newTreatmentFlowType } }) {
      this.newTreatmentFlowType = newTreatmentFlowType;
    }
  }
};
</script>
