<template>
  <div class="invite-buttons flex-column--align-center">
    <button
      class="invite-buttons__button rounded-button-white"
      @click="showMarketingCampaign(marketingCampaignsType.FACEBOOK)"
    >
      <span class="invite-buttons__button__icon fab fa-facebook-square"></span>
      <span>Facebook</span>
    </button>
    <button
      class="invite-buttons__button invite-buttons__email-button rounded-button-white"
      @click="showMarketingCampaign(marketingCampaignsType.EMAIL)"
    >
      <span class="invite-buttons__button__icon fas fa-envelope"></span>
      <span>Email</span>
    </button>
    <button
      class="invite-buttons__button rounded-button-white"
      @click="showMarketingCampaign(marketingCampaignsType.SMS)"
    >
      <span class="invite-buttons__button__icon fas fa-comment-alt"></span>
      <span>SMS</span>
    </button>
  </div>
</template>

<script>
import { isMobileDevice } from '@/modules/dashboard/utils';

import RoutingInfo from '@/modules/dashboard/components/common/RoutingInfo';

import { MESSAGE_TEMPLATE_TYPES } from '@/modules/dashboard/api/constants';

const MARKETING_CAMPAIGNS = {
  [MESSAGE_TEMPLATE_TYPES.FACEBOOK]: new RoutingInfo(
    'share-with-facebook-modal',
    'ShareWithFacebook'
  ),
  [MESSAGE_TEMPLATE_TYPES.EMAIL]: new RoutingInfo('send-with-email-modal', 'SendWithEmail'),
  [MESSAGE_TEMPLATE_TYPES.SMS]: new RoutingInfo(
    'send-with-text-message-modal',
    'SendWithTextMessage'
  )
};

export default {
  name: 'InvitePatientButtons',
  data() {
    return {
      marketingCampaignsType: MESSAGE_TEMPLATE_TYPES
    };
  },
  methods: {
    showMarketingCampaign(type) {
      const marketingCampaignInfo = MARKETING_CAMPAIGNS[type];

      if (isMobileDevice()) {
        this.$router.push({ name: marketingCampaignInfo.route });

        return;
      }

      this.$modal.show(marketingCampaignInfo.modal);
      this.$modal.hide('invite-patient-modal');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../../assets/scss/common/buttons';
@import '../../../../../assets/scss/helpers/colors';

.invite-buttons {
  font-size: 15px;
  line-height: 17px;

  &__button {
    &__icon {
      color: $grey-color;
      margin-right: 5px;
    }
  }

  &__email-button {
    margin: 30px 0;
  }
}

@media (max-width: 767px) {
  .invite-buttons {
    &__email-button {
      margin: 35px 0;
    }
  }
}
</style>
