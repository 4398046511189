<template>
  <modal
    name="mark-as-inactive-modal"
    :classes="['modal-window']"
    transition="scale"
    :adaptive="true"
    :scrollable="true"
    :width="280"
    height="auto"
    @before-open="beforeOpen"
  >
    <div class="modal-container">
      <div class="modal-window-content">
        <p class="title">
          {{ $t('dashboard.label.markInactive') }}
        </p>
        <div class="description">
          <p class="description__text">
            {{ $t('dashboard.info.markInactive') }}
          </p>
        </div>
        <div class="buttons-group">
          <a class="cancel-button" @click="cancel">
            {{ $t('dashboard.action.cancel ') }}
          </a>
          <button class="rounded-button-white" @click="markAsInactive">
            {{ $t('dashboard.action.yesMark') }}
          </button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { markPatientAsInactive } from '@/modules/dashboard/api';

export default {
  name: 'MarkAsInactiveModal',
  data() {
    return {
      patientId: null
    };
  },
  methods: {
    beforeOpen({ params: { patientId } }) {
      this.patientId = patientId;
    },
    cancel() {
      this.hideModal();
    },
    async markAsInactive() {
      if (this.patientId) {
        await markPatientAsInactive({ id: this.patientId });
        this.$emit('refreshPatients');
      }
      this.hideModal();
    },
    hideModal() {
      this.$modal.hide('mark-as-inactive-modal');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/common/buttons';

.modal-window-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 30px 20px;

  background-color: #ffffff;
  font-family: var(--u-font-family-Gilroy);
  border-radius: 8px;
}

.title {
  margin-bottom: 20px;

  font-size: 13px;
  line-height: 19px;

  color: #324752;
}

.description {
  max-width: 100%;
  margin-bottom: 30px;

  background: #fff3dc;
  border-radius: 8px;
  padding: 12px 15px;
}

.description__text {
  font-size: 11px;
  line-height: 16px;

  color: #324752;

  &:last-child {
    margin-bottom: 0;
  }
}

.cancel-button {
  font-size: 15px;
  line-height: 17px;

  text-decoration: none;

  color: #8da4b0;
}

.rounded-button-white {
  font-size: 15px;
  line-height: 17px;
  width: 120px;
}

.buttons-group {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
</style>
