<template>
  <modal
    name="new-treatment-modal"
    :classes="['modal-window']"
    transition="scale"
    scrollable
    adaptive
    width="800px"
    height="auto"
  >
    <div class="modal-container">
      <new-treatment />
    </div>
  </modal>
</template>

<script>
import NewTreatment from '@/modules/dashboard/components/dashboard/create-treatment/new-treatment/NewTreatment';

export default {
  name: 'NewTreatmentModal',
  components: { NewTreatment }
};
</script>
