<template>
  <div ref="dropdown" class="dots-menu">
    <div
      class="vertical-dots dropdown-toggle otherXS"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="true"
      @click="toggleDropdown"
    ></div>
    <div
      class="dots-menu__dropdown dropdown-menu dropdown-menu-right main-dropdown"
      :class="{ 'dots-menu__dropdown--opened': isDropdownOpened }"
    >
      <div v-if="patientPhone" class="main-dropdown__option flex">
        <a class="main-dropdown__option-link flex--align-center" :href="`tel:${patientPhone}`">
          <div class="dropdown-icon">
            <div class="icon-phone-right custom-icon"></div>
          </div>
          <div class="main-dropdown__option__text">
            {{ patientPhone }}
          </div>
        </a>
      </div>
      <div class="main-dropdown__option">
        <a class="main-dropdown__option-link flex--align-center" :href="`mailto:${patientEmail}`">
          <span class="fas fa-envelope dropdown-icon"></span>
          <div class="main-dropdown__option__text">
            {{ patientEmail }}
          </div>
        </a>
      </div>
      <div
        class="main-dropdown__option mark-as-inactive"
        :class="{ invisible: isMarkAsInactiveOptionDisabled }"
        @click="showMarkAsInactiveModal"
      >
        <a class="main-dropdown__option-link flex--align-center">
          <div class="dropdown-icon">
            <div class="icon-inactive custom-icon"></div>
          </div>
          <div class="main-dropdown__option__text">
            {{ $t('dashboard.action.markInactive') }}
          </div>
        </a>
      </div>
      <div
        v-if="isDeleteOptionEnabled"
        class="main-dropdown__option"
        @click="showDeletePatientModal"
      >
        <a class="main-dropdown__option-link flex--align-center">
          <span class="far fa-trash-alt dropdown-icon"></span>
          <div class="main-dropdown__option__text">
            {{ $t('dashboard.action.deletePatient') }}
          </div>
        </a>
      </div>
    </div>
    <div
      class="vertical-dots onlyXS"
      tabindex="-1"
      @click="togglePatientMenuVisibility"
      @blur="togglePatientMenuVisibility"
    ></div>
    <transition name="patient-menu">
      <div v-show="isPatientMenuVisible" class="patient-menu flex">
        <span
          v-if="isDeleteOptionEnabled"
          class="far fa-trash-alt patient-menu__item"
          @click="showDeletePatientModal"
        ></span>
        <a class="patient-menu__item" :href="`mailto:${patientEmail}`">
          <span class="fas fa-envelope"></span>
        </a>
        <a v-if="patientPhone" class="patient-menu__item" :href="`tel:${patientPhone}`">
          <div class="icon-phone-right"></div>
        </a>
        <div
          class="icon-inactive patient-menu__item"
          :class="{ invisible: isMarkAsInactiveOptionDisabled }"
          @click="showMarkAsInactiveModal"
        ></div>
        <div class="icon-close patient-menu__item" @click="disablePatientMenu"></div>
      </div>
    </transition>
  </div>
</template>

<script>
import { legacyDropdownMixin } from '@/modules/dashboard/mixins/dropdown/legacyDropdownMixin';

import {
  NOTIFICATION_TYPE,
  DISABLE_DELETE_OPTION_STATUSES
} from '@/modules/dashboard/api/constants';

const DISABLE_INACTIVE_OPTION_STATUSES = [
  NOTIFICATION_TYPE.INACTIVE,
  NOTIFICATION_TYPE.ONGOING_TREATMENT,
  NOTIFICATION_TYPE.TREATMENT_STARTS_IN,
  NOTIFICATION_TYPE.OFFER_SENT,
  NOTIFICATION_TYPE.ORDER_UNDER_DELIVERY
];

export default {
  name: 'PatientDetailDropdown',
  mixins: [legacyDropdownMixin],
  props: {
    patientId: {
      type: Number,
      required: true
    },
    patientStatus: {
      type: String,
      required: true
    },
    patientEmail: {
      type: String,
      required: true
    },
    patientPhone: {
      type: String,
      required: false,
      default: ''
    }
  },
  data() {
    return {
      isPatientMenuVisible: false
    };
  },
  computed: {
    isMarkAsInactiveOptionDisabled() {
      return DISABLE_INACTIVE_OPTION_STATUSES.includes(this.patientStatus);
    },
    isDeleteOptionEnabled() {
      return !DISABLE_DELETE_OPTION_STATUSES.includes(this.patientStatus);
    }
  },
  methods: {
    showMarkAsInactiveModal() {
      this.$modal.show('mark-as-inactive-modal', { patientId: this.patientId });
    },
    showDeletePatientModal() {
      this.$modal.show('delete-patient-modal', { patientId: this.patientId });
    },
    togglePatientMenuVisibility() {
      this.isPatientMenuVisible = !this.isPatientMenuVisible;
    },
    disablePatientMenu() {
      this.isPatientMenuVisible = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';
@import '../../../../assets/css/common/dropdowns.css';
@import '../../../../assets/css/common/icons.css';

.dots-menu {
  position: relative;
  opacity: 1;

  &__dropdown {
    &--opened {
      display: block;
    }
  }
}

.main-dropdown {
  padding: 10px 0;
  right: 10px;

  &__option {
    &:hover {
      background-color: $dim-background;
    }
  }

  &__option-link {
    width: 100%;
    padding: 10px 20px;

    &:hover {
      color: inherit;
    }
  }
}

.dropdown-icon {
  display: inline-block;
  width: 30px;
}

.mark-as-inactive {
  display: flex;
}

.fa-trash-alt {
  padding-left: 2px;
}

.main-dropdown__option__text {
  font-family: var(--u-font-family-Gilroy);
  font-size: 15px;
  line-height: 17px;

  color: $text-color;
  cursor: pointer;
}

.patient-menu {
  display: flex;
  align-items: center;

  position: absolute;
  right: -20px;
  bottom: -10px;

  height: 45px;
  padding-left: 20px;

  border-radius: 22px 0 0 22px;
  background-color: $dim-white;

  &__item {
    margin-right: 30px;
  }
}

.patient-menu-enter-active,
.patient-menu-leave-active {
  transition: transform 0.2s;
}

.patient-menu-enter,
.patient-menu-leave-to {
  transform: translateX(220px);
}

.invisible {
  display: none;
}
</style>
