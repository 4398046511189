<template>
  <div ref="sideBar" class="sideBar filterLetter otherXS">
    <span
      v-for="(category, index) in sidebarCategories"
      :key="index + category.letter"
      @click="activateSidebar(category)"
    >
      <a :class="{ inActive: !category.active }">{{ category.letter }}</a>
    </span>
  </div>
</template>

<script>
import stickySidebarMixin from '@/modules/dashboard/components/mixins/stickySidebarMixin';

import { SORTING_ORDER } from '@/modules/dashboard/api/constants';

export default {
  name: 'AlphabetSideBar',
  mixins: [stickySidebarMixin],
  props: {
    alphabetCategories: {
      type: Array,
      required: true
    },
    sortDirection: {
      type: String,
      required: false,
      default: SORTING_ORDER.ASC
    }
  },
  computed: {
    alphabetCategoriesReversed() {
      return [...this.alphabetCategories].reverse();
    },
    sidebarCategories() {
      if (this.sortDirection === SORTING_ORDER.DESC) {
        return this.alphabetCategoriesReversed;
      }

      return this.alphabetCategories;
    }
  },
  methods: {
    activateSidebar(category) {
      if (category && category.active) {
        this.$emit('activateSidebar', category.letter);
      }
    }
  }
};
</script>
