<template>
  <div
    v-tooltip="{
      show: isCommentVisible,
      content: commentMessage,
      classes: 'comment-tooltip'
    }"
    class="patient-record"
  >
    <div class="patient-name-column" :class="{ inactive: isInactive }" @click="showDetails">
      <div class="patient-image">
        <div v-if="thumbnail" class="image-cropper" :style="patientPhotoStyles"></div>
        <p v-else class="first-letter">{{ firstLetter }}</p>
        <div
          v-if="clinic"
          class="patient-clinic-mobile onlyXS"
          :style="`background-color: ${clinic.clinicColor}`"
        ></div>
      </div>
      <div class="patient-full-name__container">
        <div class="patient-full-name ellipsized">
          {{ fullName }}
          <div
            v-if="clinic"
            v-tooltip.top="clinic.clinicName"
            class="patient-clinic ellipsized otherXS"
            :style="{ 'background-color': clinic.clinicColor }"
          >
            {{ clinic.clinicName }}
          </div>
        </div>
        <div class="patient-email ellipsized">{{ email }}</div>
      </div>
    </div>
    <div class="patient-status-column" :class="{ inactive: isInactive }" @click="showDetails">
      <notification-subject
        class="otherXS"
        :subject="patientStatus"
        display-text
        :patient-chrono="patientChrono"
      />
      <notification-subject class="onlyXS" :subject="patientStatus" />
    </div>
    <div
      class="patient-last-activity-column"
      :class="{ inactive: isInactive }"
      @click="showDetails"
    >
      <div class="last-activity ellipsized">{{ lastActivity }}</div>
    </div>
    <div
      class="patient-registration-date-column onlyLG"
      :class="{ inactive: isInactive }"
      @click="showDetails"
    >
      <div class="registration-information flex--space-between--center">
        <span class="registration-information__date">{{ registrationDate.full }}</span>
        <div
          v-if="hasComment"
          class="icon-comment"
          @mouseover="toggleCommentVisibility"
          @mouseleave="toggleCommentVisibility"
        ></div>
      </div>
    </div>
    <patient-detail-dropdown
      :patient-id="patient.id"
      :patient-status="patientStatus"
      :patient-email="patient.email"
      :patient-phone="transformedPatientPhone"
    ></patient-detail-dropdown>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import NotificationSubject from '@/modules/dashboard/components/notifications/NotificationSubject';

import rootTypes from '@/store/types';
import { types } from '@/modules/dashboard/store/types';

import { FULL_DATE_PATTERN } from '@/constants';

import {
  buildImageUrl,
  getPatientFirstLetter,
  getPatientDisplayedName
} from '@/modules/dashboard/utils';

import { getPatientTransformedPhone } from '@/utils';
import { formatDate, formatInPastDate } from '@/modules/dashboard/utils/date-utils';

import PatientDetailDropdown from '@/modules/dashboard/components/patients/PatientDetailDropdown';
import { NOTIFICATION_TYPE } from '@/modules/dashboard/api/constants';

const FULL_DATE = 'DD MMM YYYY';
const SMALL_DATE = 'DD-MM-YYYY';

export default {
  name: 'Patient',
  components: {
    PatientDetailDropdown,
    NotificationSubject
  },
  props: {
    patient: {
      type: Object,
      required: true
    },
    showRegistrationDate: Boolean,
    thumbnail: {
      type: String,
      default: ''
    },
    toggled: Boolean,
    group: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isCommentVisible: false
    };
  },
  computed: {
    hasComment() {
      return !!this.patient.lastComment;
    },
    commentText() {
      return this.patient.lastComment.text;
    },
    commentDate() {
      return formatDate(this.patient.lastComment.creationDate, FULL_DATE_PATTERN);
    },
    commentMessage() {
      return this.isCommentVisible
        ? `<div class='comment-tooltip__message'>
                  <span class='comment-tooltip__message-date'>${this.commentDate}</span>
                  <span>${this.commentText}</span>
          </div>`
        : null;
    },
    firstLetter() {
      return getPatientFirstLetter(this.patient);
    },
    patientPhotoStyles() {
      return {
        'background-image': `url(${buildImageUrl({ convertedToBase64Photo: this.thumbnail })})`
      };
    },
    fullName() {
      return getPatientDisplayedName(this.patient);
    },
    email() {
      return this.patient.email;
    },
    clinic() {
      return this.patient.clinicDto;
    },
    registrationDate() {
      return {
        full: formatDate(this.patient.registrationDate, FULL_DATE),
        small: formatDate(this.patient.registrationDate, SMALL_DATE)
      };
    },
    lastActivity() {
      return formatInPastDate(this.patient.chronoDto, this.patient.lastActivityDate);
    },
    isInactive() {
      return this.patientStatus === NOTIFICATION_TYPE.INACTIVE;
    },
    patientStatus() {
      return this.patient.status;
    },
    patientChrono() {
      return this.patient.chronoDto;
    },
    transformedPatientPhone() {
      return getPatientTransformedPhone(this.patient);
    }
  },
  methods: {
    ...mapActions({
      setLoading: rootTypes.actions.SET_LOADING,
      setOpenedPatient: types.actions.SET_OPENED_PATIENT
    }),
    showDetails() {
      this.setLoading(true);
      this.setOpenedPatient({ id: this.patient.id, group: this.group });

      this.$router.push({
        name: 'PatientCard',
        params: {
          id: this.patient.id
        }
      });
    },
    toggleCommentVisibility() {
      this.isCommentVisible = !this.isCommentVisible;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';
@import '../../../../assets/scss/common/first-letter';
@import '../../../../assets/css/dashboard/patient-table.css';
@import '../../../../assets/css/common/icons.css';
@import '../../../../assets/css/common/tooltips.css';

.patient-record {
  height: 80px;
  cursor: pointer;

  display: flex;
  align-items: center;

  padding-left: 10px;

  color: $text-color;
}

.inactive {
  opacity: 0.5;
}

.patient-record:hover {
  background: $white;
  box-shadow: 0 4px 5px rgba(148, 166, 176, 0.1);
  border-radius: 8px;
}

.patient-name-column {
  display: flex;
  align-items: center;
}

.image-cropper {
  width: 40px;
  height: 40px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;

  background-size: cover;
  background-position: center top;
}

.patient-image {
  margin-left: 18px;
  height: 40px;
}

.patient-thumbnail {
  margin: 0 auto;
  width: auto;
  height: 100%;
}

.patient-full-name__container {
  margin-left: 15px;
  overflow: hidden;
}

.patient-full-name {
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  font-size: 15px;
  line-height: 18px;
  font-weight: 600;

  color: #1e2c33;
}

.registration-information {
  width: 108px;
}

.patient-email,
.last-activity,
.registration-information__date {
  font-size: 13px;
  line-height: 15px;
  font-weight: 300;
}

.patient-clinic {
  display: inline-block;
  border-radius: 10px;
  padding: 1px 9px;
  margin-left: 5px;
  font-size: 10px;
  line-height: 12px;
  color: $light-text-color;
}

.icon-comment {
  display: none;
}

@media screen and (max-width: 767px) {
  .patient-clinic {
    display: none;
  }

  .patient-record {
    height: 50px;
  }

  .patient-last-activity-column {
    padding-left: 14px;
  }

  .patient-full-name {
    margin-bottom: 2px;

    font-size: 12px;
    line-height: 14px;
  }

  .last-activity,
  .patient-email {
    font-size: 10px;
    line-height: 12px;
  }

  .last-activity {
    text-align: center;
  }

  .patient-image {
    margin-left: 0;
    position: relative;
    height: 30px;
  }

  .patient-full-name__container {
    margin-left: 9px;

    padding-right: 15px;
  }

  .image-cropper {
    width: 30px;
    height: 30px;
  }

  .patient-clinic-mobile {
    position: absolute;
    bottom: -3px;
    right: -3px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 2px solid $background;
    box-sizing: border-box;
  }
}

@media screen and (min-width: 1264px) {
  .icon-comment {
    display: block;
  }
}
</style>
